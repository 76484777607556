import { ReactComponent as Logo } from '../assets/logo.svg'

const PoweredBy = () => (
  <div className='flex items-center text-lg'>
    <div className='w-12 mr-2'>
      <Logo />
    </div>
    Powered by SkillsEngine
  </div>
)

export default PoweredBy
