import cc from 'classnames'

const Check = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.42857 0C1.5375 0 0 1.5375 0 3.42857V20.5714C0 22.4625 1.5375 24 3.42857 24H20.5714C22.4625 24 24 22.4625 24 20.5714V3.42857C24 1.5375 22.4625 0 20.5714 0H3.42857ZM18.0536 9.48214L11.1964 16.3393C10.6929 16.8429 9.87857 16.8429 9.38036 16.3393L5.95179 12.9107C5.44821 12.4071 5.44821 11.5929 5.95179 11.0946C6.45536 10.5964 7.26964 10.5911 7.76786 11.0946L10.2857 13.6125L16.2321 7.66071C16.7357 7.15714 17.55 7.15714 18.0482 7.66071C18.5464 8.16429 18.5518 8.97857 18.0482 9.47679L18.0536 9.48214Z'
      fill='#FBBF24'
    />
  </svg>
)

const Checkbox = ({ children, isChecked, onChange, readOnly, state, name }) => (
  <div className='checkbox'>
    <label
      className={cc('block py-4 flex items-start flex-1 group cursor-pointer', {
        'pointer-events-none': state.isLoading,
      })}
    >
      <input
        type='checkbox'
        className='sr-only'
        readOnly={readOnly}
        name={name}
        checked={isChecked}
        onChange={onChange}
      />
      <div
        className={cc('w-6 h-6 rounded-[4px] flex justify-center items-center mr-4 shrink-0 ', {
          'bg-skills-blue-400 group-hover:bg-skills-blue-300': !isChecked,
        })}
      >
        {isChecked && <Check />}
      </div>
      {children}
    </label>
  </div>
)

export default Checkbox
