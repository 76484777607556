import React from 'react'
import cc from 'classnames'
import { ReactComponent as RestartIcon } from '../assets/restart-icon.svg'
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'
import jsonRequest from '../util/jsonRequest'
import Input from './Input'
import getFormattedJobPost from '../util/getFormattedJobPost'
import { AnimatePresence, motion } from 'framer-motion'

function EmailDialog({ state, setState }) {
  const [success, setSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const handleOnClose = () => {
    setState(state => ({
      ...state,
      isEmailDialogOpen: false,
    }))
  }

  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault()
    const body = {
      generationInfo: {
        description: state.description,
        title: state.title,
        companyName: state.companyName,
        posting: getFormattedJobPost(state),
      },
      email: state.email,
      skills: state.skills,
    }
    await jsonRequest('/feedback', body)
    localStorage.setItem('subscribed', true)
    setSuccess(true)
    setTimeout(() => {
      setState(state => ({
        ...state,
        isEmailDialogOpen: false,
        isSubscribed: true,
      }))
    }, 5000)
  }

  return (
    <>
      <AnimatePresence>
        {state.isEmailDialogOpen && (
          <div>
            <motion.div
              className='fixed bottom-0 left-0 bg-white rounded-t-lg shadow-2xl p-4 z-[3000] w-full text-skills-blue-800'
              key='drop'
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 0.3 }}
            >
              <div className='md:flex md:items-center md:justify-between md:px-12 max-w-[1200px] mx-auto'>
                <div>
                  <h1 className='text-xl font-bold mb-2'>Want to see more?</h1>
                  <p className='mb-4 md:mb-0'>
                    Enter your email to be first in line for our new suite of skills based products.
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='flex'>
                    <Input
                      type='email'
                      placeholder='Enter your email'
                      className='text-white'
                      value={state.email}
                      readOnly={success || loading}
                      required
                      onChange={event =>
                        setState(state => ({ ...state, email: event.target.value }))
                      }
                    />
                    <button
                      className={cc(
                        'bg-skills-blue-700 text-white font-bold flex items-center px-4 rounded-md ml-4',
                        {
                          'opacity-50 pointer-events-none': loading,
                        }
                      )}
                      type='submit'
                    >
                      Submit
                      <div className='w-7 ml-2'>
                        <RestartIcon />
                      </div>
                    </button>
                  </div>
                </form>
                {success && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className='absolute inset-0 flex flex-col justify-center bg-white rounded-lg p-4'
                  >
                    <div className='max-w-xl mx-auto'>
                      <div className='mb-2 font-bold'>Thank you!</div>
                      <p>You are now subscribed.</p>
                    </div>
                  </motion.div>
                )}

                <button className='p-3 w-12 h-12 absolute top-1 right-1' onClick={handleOnClose}>
                  <CloseIcon />
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

export default EmailDialog
