import React from 'react'
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg'
import { Tooltip } from '@chakra-ui/react'
import PoweredBy from './PoweredBy'

const InfoTooltip = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div className='print:hidden'>
      <Tooltip
        bg='transparent'
        isOpen={isOpen}
        hasArrow
        label={
          <div className='border border-px border-skills-blue-400 bg-skills-blue-700 rounded-md p-4 w-full max-w-lg'>
            <div className='mb-4'>{children}</div>
            <PoweredBy />
          </div>
        }
      >
        <button
          className='block'
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          onClick={() => setIsOpen(true)}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
        >
          <InfoIcon />
        </button>
      </Tooltip>
    </div>
  )
}

export default InfoTooltip
