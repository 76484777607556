import React from 'react'
import removeEmojis from '../util/removeEmojis'
import cc from 'classnames'

const EditForm = ({ state, setState, setIsEditing }) => {
  const [title, setTitle] = React.useState(state.title)
  const [companyName, setCompanyName] = React.useState(state.companyName)
  const [companyUrl, setCompanyUrl] = React.useState(state.companyUrl)
  const [companyOverview, setCompanyOverview] = React.useState(state.companyOverview)

  const nonWhitespaceChangeHandler = setter => e => {
    const isWhitespace = e.target.value.trim() === ''
    if (isWhitespace) {
      setter('')
    } else {
      setter(removeEmojis(e.target.value))
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setState(state => ({
      ...state,
      title,
      companyName,
      companyUrl,
      companyOverview,
    }))
    setIsEditing(false)
  }

  const handleBlur = e => {
    let value = removeEmojis(e.target.value)
    if (!value.startsWith('http')) {
      value = `http://${value}`
    }

    setCompanyUrl(value)
  }

  const inputClass =
    'block w-full placeholder-cool-gray-400 rounded-md border-px border-transparent focus:outline-0 focus:ring-0 transition-colors py-2 px-4 mb-2 bg-skills-blue-400 focus:border-amber-400'

  return (
    <form onSubmit={handleSubmit}>
      <div className='mb-8'>
        <input
          required
          className={cc(inputClass, 'text-5xl', {})}
          type='text'
          value={title}
          placeholder='Job Title'
          onChange={nonWhitespaceChangeHandler(setTitle)}
        />
        <input
          className={cc(inputClass, 'text-3xl py-3', {})}
          type='text'
          value={companyName}
          placeholder='Company Name'
          onChange={nonWhitespaceChangeHandler(setCompanyName)}
        />
        <div className='relative'>
          <input
            tabIndex='-1'
            className='overflow-hidden opacity-0 p-0 absolute bottom-0 left-1/2 transform -translate-x-1/2 appearance-none'
            style={{ width: 0, height: 0 }}
            type='url'
            value={companyUrl}
            placeholder='Company URL'
          />
          <input
            className={cc(inputClass, 'text-2xl py-3', {})}
            type='text'
            defaultValue={companyUrl}
            placeholder='Company URL'
            onChange={handleBlur}
          />
        </div>
        <label className='mt-16 block'>
          <div className='text-2xl mb-4 font-bold'>Company Overview</div>
          <textarea
            className={cc(inputClass, '', {})}
            value={companyOverview}
            placeholder='Add some general info on the company here...'
            onChange={nonWhitespaceChangeHandler(setCompanyOverview)}
          />
        </label>
      </div>
      <div className='flex justify-end'>
        <button
          className='rounded-md text-center px-4 h-10 flex items-center justify-center font-medium transition-colors duration-300 hover:bg-skills-blue-600 mr-4'
          type='button'
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='rounded-md text-center px-4 h-10 flex items-center justify-center font-medium transition-colors duration-300 text-black bg-amber-400 hover:bg-amber-300'
        >
          Confirm changes
        </button>
      </div>
    </form>
  )
}

export default EditForm
