import cc from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import Checkbox from './Checkbox'
import Input from './Input'

function CompetencyList({ heading, structuredSkills, handleCheck, handleAddSkillSpecific, state }) {
  const motionProps = {
    variants: {
      hidden: { opacity: 0, y: 4 },
      show: { opacity: 1, y: 0 },
    },
    transition: { duration: 0.3 },
  }
  const [newSkill, setNewSkill] = React.useState('')

  const handleSubmit = e => {
    e.preventDefault()
    handleAddSkillSpecific(newSkill)
    setNewSkill('')
  }

  return (
    <div>
      <motion.h2 {...motionProps} className='uppercase'>
        {heading}
      </motion.h2>
      <ul className='mb-4'>
        {structuredSkills?.map((item, index) => {
          return (
            <motion.li
              key={item.value}
              className='border-b border-skills-blue-300/60'
              {...motionProps}
            >
              <Checkbox
                isChecked={item.selected}
                onChange={e => handleCheck(index, e.target.checked)}
                state={state}
                name={item.value}
                readOnly={state.isLoading}
              >
                {item.value}
              </Checkbox>
            </motion.li>
          )
        })}
      </ul>
      <motion.form {...motionProps} onSubmit={handleSubmit} className='flex items-center'>
        <button
          type='submit'
          className='mr-3 rounded-full block justify-center w-12 h-12 p-3 -m-3 shrink-0'
          disabled={state.isLoading}
        >
          <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M12 24C14.3734 24 16.6934 23.2962 18.6668 21.9776C20.6402 20.6591 22.1783 18.7849 23.0865 16.5922C23.9948 14.3995 24.2324 11.9867 23.7694 9.65892C23.3064 7.33115 22.1635 5.19295 20.4853 3.51472C18.807 1.83649 16.6689 0.693604 14.3411 0.230582C12.0133 -0.232441 9.60051 0.00519943 7.4078 0.913451C5.21508 1.8217 3.34094 3.35977 2.02236 5.33316C0.703788 7.30655 0 9.62663 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24ZM10.8753 16.1253V13.1253H7.87533C7.5817 13.1182 7.30251 12.9965 7.09736 12.7863C6.89222 12.5761 6.77739 12.294 6.77739 12.0003C6.77739 11.7066 6.89222 11.4245 7.09736 11.2143C7.30251 11.0041 7.5817 10.8825 7.87533 10.8753H10.8753V7.87534C10.8825 7.58171 11.0041 7.30251 11.2143 7.09737C11.4245 6.89222 11.7066 6.77739 12.0003 6.77739C12.294 6.77739 12.5761 6.89222 12.7863 7.09737C12.9965 7.30251 13.1182 7.58171 13.1253 7.87534V10.8753H16.1253C16.419 10.8825 16.6982 11.0041 16.9033 11.2143C17.1084 11.4245 17.2233 11.7066 17.2233 12.0003C17.2233 12.294 17.1084 12.5761 16.9033 12.7863C16.6982 12.9965 16.419 13.1182 16.1253 13.1253H13.1253V16.1253C13.1182 16.419 12.9965 16.6982 12.7863 16.9033C12.5761 17.1084 12.294 17.2233 12.0003 17.2233C11.7066 17.2233 11.4245 17.1084 11.2143 16.9033C11.0041 16.6982 10.8825 16.419 10.8753 16.1253Z'
              fill='#F9FAFB'
            />
          </svg>
          <div className='sr-only'>Add</div>
        </button>
        <Input
          required
          value={newSkill}
          onChange={event => setNewSkill(event.target.value)}
          className={cc({ 'pointer-events-none': state.isLoading })}
          placeholder='Add additional skills'
          readOnly={state.isLoading}
        />
      </motion.form>
    </div>
  )
}

export default CompetencyList
