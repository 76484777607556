import React from 'react'
import cc from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Flex } from '@chakra-ui/react'
import CompetencyList from './CompetencyList'
import Chatdots from './Chatdots'
import OccupationalHeading from './OccupationalHeading'
import FoundationalHeading from './FoundationalHeading'
import { useNavigate } from 'react-router-dom'
import getFormattedJobPost from '../util/getFormattedJobPost'
import { Navigate } from 'react-router-dom'
import jsonRequest from '../util/jsonRequest'

const LOADING_MESSAGES = [
  'Generating your job posting, this may take a while...',
  'Hang on, we‘re almost done...',
  'Just a few more seconds...',
]

const SkillsStep = ({ state, setState }) => {
  const navigate = useNavigate()
  const [loadingMessage, setLoadingMessage] = React.useState(0)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cycleLoadingMessage = () => {
    setLoadingMessage(msg => (msg + 1) % LOADING_MESSAGES.length)
  }

  const handleCheck = key => async (index, isChecked) => {
    const skill = state.skills[key][index]
    setState(state => ({
      ...state,
      skills: {
        ...state.skills,
        [key]: state.skills[key].map((skill, i) =>
          i === index ? { ...skill, selected: isChecked } : skill
        ),
      },
    }))
    let dataToSend = {
      generationInfo: {
        description: state.description,
        title: state.title,
        companyName: state.companyName,
        posting: getFormattedJobPost(state),
      },
      email: state.email,
      skills: state.skills,
      skill: skill.value,
      skillAdded: isChecked,
    }
    await jsonRequest('/skillchange', dataToSend)
  }

  const handleAdd = async (key, value) => {
    setState(state => ({
      ...state,
      skills: {
        ...state.skills,
        [key]: [...state.skills[key], { selected: true, value }],
      },
    }))
    let dataToSend = {
      generationInfo: {
        description: state.description,
        title: state.title,
        companyName: state.companyName,
        posting: getFormattedJobPost(state),
      },
      email: state.email,
      skills: state.skills,
      skill: value,
      skillAdded: true,
    }
    await jsonRequest('/skillchange', dataToSend)
  }

  const handleError = () => {
    setState(state => ({ ...state, isErrorDialogOpen: true, isLoading: false }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setState(state => ({
      ...state,
      isLoading: true,
    }))
    let dataToSend = {
      generationInfo: {
        description: state.description,
        title: state.title,
        companyName: state.companyName,
      },
      skills: state.skills,
    }
    const interval = setInterval(cycleLoadingMessage, 6000)
    let data
    try {
      data = await jsonRequest('/submitfinalskills', dataToSend)
    } catch (e) {
      handleError()
      return
    }
    if (!data.success) {
      handleError()
      return
    }

    setState(state => ({
      ...state,
      isLoading: false,
      computed: {
        ...state.computed,
        description: data.message,
        descriptionObject: data.parse_result,
        experienceJobDescription: data.experience_necessary_parse,
      },
    }))
    clearInterval(interval)
    navigate('/job-post')
  }

  if (!state.title) {
    return <Navigate to='/' />
  }
  const motionProps = {
    variants: {
      hidden: { opacity: 0, y: 5 },
      show: { opacity: 1, y: 0 },
    },
    transition: { duration: 0.3 },
  }

  return (
    <motion.div
      variants={motionProps.variants}
      initial='hidden'
      animate='show'
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        staggerChildren: 0.02,
      }}
    >
      <Flex marginTop={['10%', '30px']} maxW={'90vw'} flexDir={'column'}>
        <motion.div {...motionProps} className='text-center mb-8'>
          <h1 className='text-3xl font-medium mb-2'>Customize your job post</h1>
          <p>Deselect skills that do not apply and add new ones</p>
        </motion.div>
        <div className='mb-8'>
          <CompetencyList
            handleAddSkillSpecific={skill => handleAdd('required_occupational_competencies', skill)}
            heading={<OccupationalHeading />}
            fieldKey={'required_occupational_competencies'}
            structuredSkills={state.skills.required_occupational_competencies}
            handleCheck={handleCheck('required_occupational_competencies')}
            state={state}
          />
        </div>
        <div className='mb-8'>
          <CompetencyList
            handleAddSkillSpecific={skill => handleAdd('required_foundational_competencies', skill)}
            heading={<FoundationalHeading />}
            fieldKey='required_foundational_competencies'
            structuredSkills={state.skills.required_foundational_competencies}
            handleCheck={handleCheck('required_foundational_competencies')}
            state={state}
          />
        </div>

        <motion.div {...motionProps}>
          <button
            type='button'
            disabled={state.isLoading}
            onClick={handleSubmit}
            className={cc(
              'rounded-md max-w-[344px] text-center w-full mx-auto block h-10 flex items-center justify-center font-medium transition-colors duration-300',
              {
                'bg-cool-gray-300 text-[#525252]': state.isLoading,
                'bg-amber-400 text-skills-blue-800': !state.isLoading,
              }
            )}
          >
            {state.isLoading ? <Chatdots color='white'></Chatdots> : 'Confirm'}
          </button>
        </motion.div>
        <div className='h-16 relative'>
          <AnimatePresence>
            <motion.div
              className='text-center mt-4 mb-8 text-cool-gray-300 absolute inset-0'
              key={loadingMessage}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: state.isLoading ? 1 : 0, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
            >
              {LOADING_MESSAGES[loadingMessage]}
            </motion.div>
          </AnimatePresence>
        </div>
      </Flex>
    </motion.div>
  )
}

export default SkillsStep
