import '../App.css'

export default function Chatdots({ color = '#63595c' }) {
  return (
    <>
      <div className='hollow-dots-spinner'>
        <div style={{ border: `calc(15px / 5) solid ${color}` }} className='dot'></div>
        <div style={{ border: `calc(15px / 5) solid ${color}` }} className='dot'></div>
        <div style={{ border: `calc(15px / 5) solid ${color}` }} className='dot'></div>
      </div>
    </>
  )
}
