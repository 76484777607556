const jsonRequest = async (endpoint, body) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method: 'POST',
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
  const data = await response.json()
  return data
}

export default jsonRequest
