import InfoTooltip from './InfoTooltip'

const OccupationalHeading = () => (
  <div className='flex items-center font-medium mb-2'>
    <div className='mr-2 uppercase'>Occupational</div>
    <InfoTooltip>
      <div className='text-md'>
        <p className='font-medium mb-2 text-lg'>Occupational Competency</p>
        <p>
          Skills, abilities, behaviors and characteristics that are specific to a particular
          industry or job.
        </p>
      </div>
    </InfoTooltip>
  </div>
)

export default OccupationalHeading
