const getFormattedJobPost = state => {
  let text = state.title
  if (state.companyName) {
    text += `\n\n${state.companyName.trim()}`
  }
  if (state.companyUrl) {
    text += `\n\n${state.companyUrl.trim()}`
  }
  if (state.companyOverview) {
    text += `\n\nCompany Overview:\n${state.companyOverview.trim()}`
  }
  if (state.computed.description) {
    text += `\n\n${state.computed.description.trim()}`
  }
  return text
}

export default getFormattedJobPost
