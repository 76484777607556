import React from 'react'
import cc from 'classnames'
import { motion } from 'framer-motion'
import copy from 'copy-to-clipboard'
import {
  Flex,
  Fade,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react'
import { Navigate } from 'react-router-dom'

import ConfirmationDialog from './ConfirmationDialog'
import { ReactComponent as CheckIcon } from '../assets/check-icon.svg'
import Input from './Input'
import EditForm from './EditForm'
import PoweredBy from './PoweredBy'
import getFormattedJobPost from '../util/getFormattedJobPost'
import OccupationalHeading from './OccupationalHeading'
import FoundationalHeading from './FoundationalHeading'
import jsonRequest from '../util/jsonRequest'
import { ReactComponent as Thumbsup } from '../assets/thumbsup1.svg'
import { ReactComponent as Thumbsdown } from '../assets/thumbsdown1.svg'
import { ReactComponent as EditIcon } from '../assets/edit-icon.svg'
import { ReactComponent as ShareIcon } from '../assets/share-icon.svg'
import { ReactComponent as PrintIcon } from '../assets/print-icon.svg'
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg'
import { ReactComponent as CopyIcon } from '../assets/copy-icon.svg'
import { ReactComponent as ArrowBackIcon } from '../assets/arrow-back.svg'

const slug = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

const FinishStep = ({ state, setState, onReset }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isCopied, setIsCopied] = React.useState(false)
  const [isSubmittingFeedback, setIsSubmittingFeedback] = React.useState(false)
  const originalInput = React.useRef({
    title: state.title,
    companyName: state.companyName,
    description: state.description,
  })
  const [isEditing, setIsEditing] = React.useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setIsEditing(false)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let body = {
    generationInfo: {
      description: state.description,
      title: state.title,
      companyName: state.companyName,
      posting: getFormattedJobPost(state),
    },
    email: state.email,
    skills: state.skills,
    feedback: state.feedback,
    thumbs: state.selectedThumb === 'up' ? true : false,
  }

  const handleStartOver = () => {
    setIsDialogOpen(true)
  }

  const getDownloadHref = () => {
    return 'data:text/plain;charset=utf-8,' + encodeURIComponent(getFormattedJobPost(state))
  }

  const feedbackSubmit = async e => {
    e.preventDefault()
    setIsSubmittingFeedback(true)
    await jsonRequest('/feedback', body)
    setState(state => ({
      ...state,
      submittedFeedback: true,
    }))
    setIsSubmittingFeedback(false)
  }

  const handleThumbsUp = async () => {
    setState(state => ({
      ...state,
      selectedThumb: 'up',
    }))
    await jsonRequest('/thumbs', { ...body, thumbs: true })
  }

  const handleThumbsDown = async () => {
    setState(state => ({
      ...state,
      selectedThumb: 'down',
    }))
    await jsonRequest('/thumbs', { ...body, thumbs: false })
  }

  React.useEffect(() => {
    if (localStorage.getItem('subscribed') || state.isSubscribed) {
      return
    }
    const timeout = setTimeout(() => {
      setState(state => {
        if (state.isShareDialogOpen) {
          return state
        }
        return { ...state, isEmailDialogOpen: true }
      })
    }, 15000)
    return () => clearTimeout(timeout)
  }, [setState]) // eslint-disable-line react-hooks/exhaustive-deps

  const motionProps = {
    variants: {
      hidden: { opacity: 0, y: 5 },
      show: { opacity: 1, y: 0 },
    },
    transition: { duration: 0.3 },
  }

  if (!state.title) {
    return <Navigate to='/' />
  }

  return (
    <motion.div
      variants={motionProps.variants}
      onSubmit={handleSubmit}
      initial='hidden'
      animate='show'
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        staggerChildren: 0.05,
      }}
    >
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title='Are you sure you want to start over?'
        description='All progress will be lost.'
        confirmLabel='Start Over'
        onConfirm={onReset}
      />
      <div className='print:hidden'>
        <motion.div {...motionProps}>
          <Accordion width={'100%'} allowToggle>
            <AccordionItem border='1px solid rgba(255, 255, 255, 0.3)' borderRadius='4px'>
              <AccordionButton>
                <div className='flex items-center justify-between w-full'>
                  <div className='uppercase font-medium'>View original input</div>
                  <AccordionIcon />
                </div>
              </AccordionButton>
              <AccordionPanel>
                <Fade in={true} transition={{ enter: { duration: 0.35 } }}>
                  <div className='py-2'>
                    <p className='mb-2'>{originalInput.current.title}</p>
                    <p className='mb-2 text-cool-gray-400'>{originalInput.current.companyName}</p>
                    {originalInput.current.description && (
                      <p>{originalInput.current.description}</p>
                    )}
                  </div>
                </Fade>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </motion.div>
        <motion.div {...motionProps}>
          <div className='flex justify-end mb-8 mt-1'>
            <button
              onClick={handleStartOver}
              className='text-amber-400 flex items-center border-b border-amber-400'
            >
              <div className='w-3 mr-2 inline-block'>
                <ArrowBackIcon />
              </div>
              Start Over
            </button>
          </div>
          <div className='flex justify-end mb-8'>
            <button
              className={cc(
                'bg-skills-blue-300 w-10 h-10 flex items-center justify-center rounded-md mr-2 transition-colors',
                {
                  'bg-[#D1D5DB] text-[#525252]': isEditing,
                }
              )}
              onClick={() => setIsEditing(is => !is)}
            >
              <div className='w-5'>
                <EditIcon />
              </div>
            </button>
            <button
              className='bg-skills-blue-300 w-10 h-10 flex items-center justify-center rounded-md mr-2'
              onClick={() =>
                setState(state => ({
                  ...state,
                  isShareDialogOpen: true,
                }))
              }
            >
              <div className='w-4'>
                <ShareIcon />
              </div>
            </button>
            <button
              className='bg-skills-blue-300 w-10 h-10 flex items-center justify-center rounded-md mr-2'
              onClick={() => window.print()}
            >
              <div className='w-5'>
                <PrintIcon />
              </div>
            </button>
            <a
              href={getDownloadHref()}
              className='bg-skills-blue-300 w-10 h-10 flex items-center justify-center rounded-md mr-2'
              download={`job-post-${slug(state.title)}.txt`}
            >
              <div className='w-4'>
                <DownloadIcon />
              </div>
            </a>
            <div className='relative'>
              <button
                className='bg-skills-blue-300 px-4 h-10 flex items-center justify-center rounded-md'
                onClick={() => {
                  copy(getFormattedJobPost(state))
                  setIsCopied(true)
                }}
              >
                <div className='w-5 mr-2'>
                  <CopyIcon />
                </div>
                Copy All
              </button>
              {isCopied && (
                <motion.div
                  key={copy.id}
                  className='absolute inset-0 bg-[#6EE7B7] text-[#064E3B] border border-[#34D399] flex items-center justify-center rounded-md select-none'
                  initial={{ opacity: 1 }}
                  animate={{
                    opacity: 0,
                    transition: {
                      duration: 0.3,
                      delay: 1.5,
                    },
                  }}
                  onAnimationComplete={() => setIsCopied(false)}
                >
                  <div className='w-4 mr-2'>
                    <CheckIcon />
                  </div>
                  Copied!
                </motion.div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
      <motion.div {...motionProps} className='mb-8 pb-8 border-b border-skills-blue-500'>
        {isEditing ? (
          <EditForm
            state={state}
            setState={setState}
            onSubmit={handleSubmit}
            setIsEditing={setIsEditing}
          />
        ) : (
          <div>
            <h1 className='text-5xl font-medium mb-4'>{state.title}</h1>
            {state.companyName && (
              <p className='text-3xl mb-2 text-gray-400 italic'>{state.companyName}</p>
            )}
            {state.companyUrl && (
              <p className='text-2xl mb-2'>
                <a href={state.companyUrl} rel='noreferrer' target='_blank'>
                  {state.companyUrl}
                </a>
              </p>
            )}
            {state.companyOverview && (
              <div className='mt-16'>
                <h2 className='text-2xl mb-4 font-bold'>Company Overview</h2>
                <p>{state.companyOverview}</p>
              </div>
            )}
          </div>
        )}
      </motion.div>
      <motion.div {...motionProps}>
        <div className='mb-16'>
          <div className='pb-8 mb-8 border-b border-skills-blue-500'>
            <div className='mb-6'>
              <h2 className='text-2xl mb-4 font-bold'>Job Summary</h2>
              <p>{state.computed.descriptionObject['Job Summary and Responsibilities']}</p>
            </div>
            <div className='flex justify-end'>
              <PoweredBy />
            </div>
          </div>
          <div className='pb-0 mb-8 border-b border-skills-blue-500'>
            <h2 className='text-2xl mb-4 font-bold'>Competencies</h2>
            {Object.keys(state.computed.descriptionObject['Competencies']).map(subKey => (
              <div key={subKey} className='mb-8 break-after-avoid-page'>
                <h3 className='mb-2'>
                  {subKey === 'Foundational' ? <FoundationalHeading /> : <OccupationalHeading />}
                </h3>
                <ul className='pl-8 print:list-disc'>
                  {state.computed.descriptionObject['Competencies'][subKey].map(item => (
                    <li className='py-2 relative' key={item}>
                      <div className='rounded-full bg-amber-400 w-3 h-3 absolute top-4 -left-8 print:hidden' />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className='pb-4 break-after-avoid-page'>
            <h2 className='text-2xl mb-4 font-bold'>Experience & Qualifications</h2>
            <ul className='pl-8 print:list-disc'>
              {state.computed.experienceJobDescription.map(item => {
                return (
                  <li className='py-2 relative' key={item}>
                    <div className='rounded-full bg-amber-400 w-3 h-3 absolute top-4 -left-8 print:hidden' />
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='flex justify-end'>
            <PoweredBy />
          </div>
        </div>
      </motion.div>
      <motion.div {...motionProps}>
        <div className='bg-skills-blue-500 rounded-2xl pb-8 pt-4 mb-8 print:hidden'>
          <Flex flexDirection={'row'} justifyContent='center'>
            <button
              disabled={state.submittedFeedback}
              className={cc(
                'w-16 h-16 rounded-md md:hover:bg-skills-blue-400 flex justify-center items-center transition-colors mr-4',
                {
                  'pointer-events-none': state.submittedFeedback,
                }
              )}
              onClick={handleThumbsUp}
            >
              <Thumbsup
                width={'46px'}
                height={'46px'}
                fill={state.selectedThumb === 'up' ? '#ffbd00' : 'white'}
              />
            </button>
            <button
              disabled={state.submittedFeedback}
              className={cc(
                'w-16 h-16 rounded-md md:hover:bg-skills-blue-400 flex justify-center items-center transition-colors',
                {
                  'pointer-events-none': state.submittedFeedback,
                }
              )}
              onClick={handleThumbsDown}
            >
              <Thumbsdown
                width={'46px'}
                height={'46px'}
                fill={state.selectedThumb === 'down' ? '#ffbd00' : 'white'}
              />
            </button>
          </Flex>

          {state.selectedThumb ? (
            <>
              {state.submittedFeedback ? (
                <div className='text-center'>
                  <p className='text-3xl mb-2'>Thank you for your feedback!</p>
                  <p className='text-xl text-cool-gray-400'>
                    Your contributions help us make our product better.
                  </p>
                </div>
              ) : (
                <form onSubmit={feedbackSubmit} className='max-w-md px-4 mx-auto mt-4'>
                  <div className='mb-4'>
                    <Input
                      placeholder='What would have the ideal output looked like?'
                      value={state.feedback}
                      readOnly={isSubmittingFeedback}
                      required
                      onChange={event =>
                        setState(state => ({ ...state, feedback: event.target.value }))
                      }
                    />
                  </div>
                  <div className='flex justify-end'>
                    <button
                      className={cc(
                        'rounded-md text-center px-4 h-10 flex items-center justify-center font-medium transition-colors duration-300 hover:bg-skills-blue-600 mr-4',
                        {
                          'pointer-events-none opacity-50': isSubmittingFeedback,
                        }
                      )}
                      onClick={() => setState(state => ({ ...state, selectedThumb: null }))}
                      type='button'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className={cc(
                        'rounded-md text-center px-4 h-10 flex items-center justify-center font-medium transition-colors duration-300 text-black bg-amber-400 hover:bg-amber-300',
                        {
                          'pointer-events-none opacity-50': isSubmittingFeedback,
                        }
                      )}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </>
          ) : (
            !state.submittedFeedback && (
              <div className='text-center'>
                <p className='text-3xl font-bold mb-4'>How did we do?</p>
                <p className='text-2xl'>Was this job posting useful to you?</p>
              </div>
            )
          )}
        </div>
      </motion.div>
    </motion.div>
  )
}

export default FinishStep
