import React from 'react'
import { hydrate, render } from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { HashRouter } from 'react-router-dom'
import '@fontsource/barlow'
import '@fontsource/barlow/500.css'
import '@fontsource/barlow/700.css'
import '@fontsource/barlow/400-italic.css'

const theme = extendTheme({
  fonts: {
    heading: 'Barlow, sans-serif',
    body: 'Barlow, sans-serif',
  },
  colors: {
    'cool-gray': {
      50: '#F9FAFB',
      400: '#9CA3AF',
    },
    'skills-blue': {
      50: '#e3e6eb',
      100: '#b9bfcf',
      200: '#8d97ae',
      300: '#63708e',
      400: '#445379',
      500: '#253966',
      600: '#1f335f',
      700: '#182b54',
      800: '#112248',
      900: '#091331',
    },
  },
  styles: {
    global: () => ({
      html: {
        fontSize: '16px',
      },
      body: {
        bg: '',
        color: '',
      },
    }),
  },
})

const root = document.getElementById('root')

const AppWithRouter = () => (
  <ChakraProvider theme={theme}>
    <HashRouter>
      <App />
    </HashRouter>
  </ChakraProvider>
)

if (root.hasChildNodes()) {
  hydrate(<AppWithRouter />, root)
} else {
  render(<AppWithRouter />, root)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
