import InfoTooltip from './InfoTooltip'

const FoundationalHeading = () => (
  <div className='flex items-center font-medium'>
    <div className='mr-2 uppercase'>Foundational</div>
    <InfoTooltip>
      <div className='text-md'>
        <p className='font-medium mb-2 text-lg'>Foundational Competency</p>
        <p>
          Attributes, personality traits and professional values that are transferable across
          different jobs and industries.
        </p>
      </div>
    </InfoTooltip>
  </div>
)

export default FoundationalHeading
