const Footer = () => {
  return (
    <footer className='bg-skills-blue-600 py-3 px-16 print:hidden'>
      <div className='max-w-[1200px] mx-auto flex justify-center md:justify-between'>
        <a
          className='hidden md:block'
          href='https://www.skillsengine.com/about'
          target='_blank'
          rel='noreferrer'
        >
          About
        </a>
        <a
          className='hidden md:block'
          href='https://support.skillsengine.com/hc/en-us'
          target='_blank'
          rel='noreferrer'
        >
          Support
        </a>
        <p className='uppercase text-center'>
          Copyright © {new Date().getFullYear()} Skillsengine. All rights reserved.
        </p>
        <a
          className='hidden md:block'
          href='https://www.skillsengine.com/terms-of-service'
          target='_blank'
          rel='noreferrer'
        >
          Terms
        </a>
        <a
          className='hidden md:block'
          href='https://www.skillsengine.com/privacy-policy'
          target='_blank'
          rel='noreferrer'
        >
          Privacy
        </a>
      </div>
    </footer>
  )
}

export default Footer
