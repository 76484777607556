import React from 'react'
import { ReactComponent as RestartIcon } from '../assets/restart-icon.svg'
import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { AnimatePresence, motion } from 'framer-motion'

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, title, description, confirmLabel }) => {
  const confirmButtonRef = React.useRef()

  const handleConfirm = async () => {
    await onConfirm()
    onClose()
  }

  const handleOpenAutoFocus = () => {
    confirmButtonRef.current.focus()
  }

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onClose}>
      <AnimatePresence>
        {isOpen && (
          <AlertDialog.Portal forceMount>
            <>
              <AlertDialog.Overlay forceMount>
                <motion.div
                  key='overlay'
                  className='fixed inset-0 bg-black bg-opacity-50 z-[2000]'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                />
              </AlertDialog.Overlay>
              <AlertDialog.Content forceMount onOpenAutoFocus={handleOpenAutoFocus}>
                <motion.div
                  className='fixed bg-white rounded-lg shadow-2xl p-4 z-[3000] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-md text-skills-blue-800'
                  key='drop'
                  style={{ width: 'calc(100% - 16px)' }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <AlertDialog.Title asChild>
                    <h1 className='dialog-title font-bold text-xl mb-2'>{title}</h1>
                  </AlertDialog.Title>
                  <AlertDialog.Description className='mb-4'>{description}</AlertDialog.Description>
                  <div className='flex gap-4 justify-end'>
                    <button
                      className='border border-cool-gray-300 font-bold flex items-center h-12 px-4 rounded-md'
                      onClick={onClose}
                    >
                      Cancel
                      <div className='w-7 bg-[#EFEFEF] rounded-md h-5 flex justify-center items-center text-[10px] font-medium ml-2'>
                        ESC
                      </div>
                    </button>
                    <button
                      className='bg-skills-blue-700 text-white font-bold flex items-center h-12 px-4 rounded-md'
                      onClick={handleConfirm}
                      ref={confirmButtonRef}
                    >
                      {confirmLabel}
                      <div className='w-7 ml-2'>
                        <RestartIcon />
                      </div>
                    </button>
                  </div>
                </motion.div>
              </AlertDialog.Content>
            </>
          </AlertDialog.Portal>
        )}
      </AnimatePresence>
    </AlertDialog.Root>
  )
}

export default ConfirmationDialog
