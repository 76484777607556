import cc from 'classnames'
import { motion } from 'framer-motion'
import removeEmojis from '../util/removeEmojis'
import jsonRequest from '../util/jsonRequest'
import Input, { inputClasses } from './Input'
import { useNavigate } from 'react-router-dom'
import Chatdots from './Chatdots'

const GenerateStep = ({ setState, state }) => {
  const navigate = useNavigate()

  const handleError = () => {
    setState(state => ({ ...state, isErrorDialogOpen: true, isLoading: false }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setState(state => ({
      ...state,
      isLoading: true,
    }))
    let dataToSend = {
      generationInfo: {
        description: state.description,
        title: state.title,
        companyName: state.companyName,
      },
    }
    let data
    try {
      data = await jsonRequest('/submitjobtitle', dataToSend)
    } catch (e) {
      handleError()
      return
    }
    if (!data.success) {
      handleError()
      return
    }
    let skills_object = data.skills_object
    const updatedSkills = {}

    Object.keys(skills_object).forEach(key => {
      const newArr = skills_object[key].map(item => {
        return {
          selected: true,
          value: item,
        }
      })
      updatedSkills[key] = newArr
    })

    setState({
      ...state,
      isLoading: false,
      skills: updatedSkills,
    })
    navigate('/skills')
  }

  const canAdvance = state.title.length > 0
  const motionProps = {
    variants: {
      hidden: { opacity: 0, y: 5 },
      show: { opacity: 1, y: 0 },
    },
    transition: { duration: 0.3 },
  }

  return (
    <motion.form
      variants={motionProps.variants}
      onSubmit={handleSubmit}
      initial='hidden'
      animate='show'
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        staggerChildren: 0.05,
      }}
    >
      <motion.div {...motionProps} className='text-center mb-8 opacity-0'>
        <h1 className='text-3xl font-medium mb-2'>Get started</h1>
        <p>Type or copy/paste some text. The more descriptive the better!</p>
      </motion.div>
      <fieldset disabled={state.isLoading}>
        <div className='mb-8'>
          <motion.div {...motionProps} className='mb-4 opacity-0'>
            <Input
              placeholder='What‘s this job called?'
              value={state.title}
              onChange={event => setState(state => ({ ...state, title: event.target.value }))}
            />
          </motion.div>
          <motion.div {...motionProps} className='mb-4 opacity-0'>
            <textarea
              className={inputClasses.replace('truncate', '')}
              placeholder='Tell us more about your company and job responsibilities (optional)'
              value={state.description}
              onChange={event =>
                setState(state => ({ ...state, description: removeEmojis(event.target.value) }))
              }
            />
          </motion.div>
          <motion.div {...motionProps} className='mb-4 opacity-0'>
            <Input
              placeholder='Enter company name (optional)'
              value={state.companyName}
              onChange={event => setState(state => ({ ...state, companyName: event.target.value }))}
            />
          </motion.div>
        </div>
      </fieldset>
      <motion.div {...motionProps} className='mb-4 opacity-0'>
        <button
          type='submit'
          disabled={state.isLoading || !canAdvance}
          className={cc(
            'rounded-md max-w-[344px] text-center w-full mx-auto block h-10 flex items-center justify-center font-medium transition-colors duration-300',
            {
              'bg-cool-gray-300 text-[#525252]': !canAdvance || state.isLoading,
              'bg-amber-400 text-skills-blue-800': canAdvance && !state.isLoading,
            }
          )}
        >
          {state.isLoading ? <Chatdots color='white' /> : 'Generate'}
        </button>
      </motion.div>
    </motion.form>
  )
}

export default GenerateStep
