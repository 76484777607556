import React from 'react'
import { Link } from 'react-router-dom'
import cc from 'classnames'
import { ReactComponent as Logo } from '../assets/logo.svg'
import * as Dialog from '@radix-ui/react-dialog'
import MenuButton from './MenuButton'
import { AnimatePresence, motion } from 'framer-motion'

const NavItem = ({ children, href, active, secondary, onClick }) => (
  <li className=''>
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className={cc('p-2 rounded-md flex', {
        'bg-skills-blue-900': active,
        'text-cool-gray-400': secondary,
      })}
      onClick={onClick}
    >
      {children}
    </a>
  </li>
)

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false)
  React.useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isNavOpen)
    document.body.classList.toggle('fixed', isNavOpen)
  }, [isNavOpen])
  return (
    <>
      <nav className='bg-skills-blue-500 relative z-10 print:hidden'>
        <div className='flex items-center justify-between h-16 md:justify-start md:space-x-10 max-w-[1200px] px-4 mx-auto'>
          <Link to='/' className='flex items-center'>
            <div className='w-12 color-[#F9FAFB]'>
              <Logo />
            </div>
            <span className='ml-3 font-medium text-2xl'>Job Post Generator</span>
          </Link>
          <div className='flex items-center justify-end flex-1'>
            <a
              href='https://www.skillsengine.com/about'
              target='_blank'
              rel='noreferrer'
              className='hidden md:inline-flex inline-flex items-center justify-center rounded-md  px-4 py-2 font-medium hover:bg-skills-blue-400 transition-colors'
            >
              About
            </a>
            <a
              href='https://skillsengine.com/next'
              target='_blank'
              rel='noreferrer'
              className='hidden md:inline-flex ml-4 inline-flex items-center justify-center rounded-md  bg-amber-400 px-4 py-2 font-medium text-skills-blue-700 hover:bg-amber-300 transition-colors'
            >
              Sign up
            </a>
            <button
              onClick={() => setIsNavOpen(true)}
              className='w-12 h-12 p-4 flex justify-center items-center text-white md:hidden'
            >
              <MenuButton
                isOpen={isNavOpen}
                strokeWidth='1'
                width='22'
                height='16'
                lineProps={{ stroke: '#fff' }}
              />
            </button>
          </div>
        </div>
      </nav>

      <Dialog.Root isOpen={isNavOpen} onOpenChange={() => setIsNavOpen(false)}>
        <AnimatePresence>
          {isNavOpen && (
            <Dialog.Portal forceMount>
              <Dialog.Content>
                <motion.div
                  className='fixed inset-0 bg-skills-blue-500 pt-16 h-screen'
                  key='nav'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ul className='p-2 border-b border-[#374151]'>
                    <NavItem href='https://skillsengine.com'>SkillsEngine Home</NavItem>
                    <NavItem
                      href='#'
                      active
                      onClick={e => {
                        e.preventDefault()
                        setIsNavOpen(false)
                      }}
                    >
                      Job Generator
                    </NavItem>
                    <NavItem href='https://www.skillsengine.com/about'>About</NavItem>
                    <NavItem href='https://www.skillsengine.com/next'>Sign up</NavItem>
                  </ul>
                  <ul className='p-2'>
                    <NavItem secondary href='https://support.skillsengine.com/hc/en-us'>
                      Support
                    </NavItem>
                    <NavItem secondary href='https://www.skillsengine.com/terms-of-service'>
                      Terms
                    </NavItem>
                    <NavItem secondary href='https://www.skillsengine.com/privacy-policy'>
                      Privacy
                    </NavItem>
                  </ul>

                  <button
                    className='p-3 w-12 h-12 absolute top-2 right-3'
                    onClick={() => setIsNavOpen(false)}
                  >
                    <span className='sr-only'>Close menu</span>
                  </button>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </>
  )
}
