import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { useUpdateEffect } from 'react-use'
import { Routes, Route, useLocation } from 'react-router-dom'

import './App.css'
import Navbar from './components/Navbar.js'
import Footer from './components/Footer.js'
import Breadcrumbs from './components/Breadcrumbs'
import EmailDialog from './components/EmailDialog.js'
import ShareDialog from './components/ShareDialog.js'
import ErrorDialog from './components/ErrorDialog.js'

import GenerateStep from './components/GenerateStep'
import FinishStep from './components/FinishStep'
import SkillsStep from './components/SkillsStep'

const INITIAL_STATE = {
  title: '',
  description: '',
  companyUrl: '',
  companyName: '',
  companyOverview: '',
  skills: {},

  // computed
  computed: {
    description: '',
    descriptionObject: {},
    experienceJobDescription: '',
  },

  // feedback
  selectedThumb: null,
  feedback: '',
  submittedFeeback: false,
  email: '',

  // UI
  isLoading: false,
  isEmailDialogOpen: false,
  isErrorDialogOpen: false,
  isShareDialogOpen: false,
  isSubscribed: false,
}

function App() {
  const [state, setState] = React.useState(INITIAL_STATE)
  const location = useLocation()
  const { pathname } = location

  React.useEffect(() => {
    document.addEventListener('copy', function (e) {
      const text_only = document.getSelection().toString()
      const clipdata = e.clipboardData || window.clipboardData
      clipdata.setData('text/plain', text_only)
      clipdata.setData('text/html', text_only)
      e.preventDefault()
    })
  }, [])

  useUpdateEffect(() => {
    if (pathname === '/') {
      setState({
        ...INITIAL_STATE,
        email: state.email,
        isEmailDialogOpen: state.isEmailDialogOpen,
        isShareDialogOpen: state.isShareDialogOpen,
        isSubscribed: state.isSubscribed,
      })
    }
    if (pathname === '/skills') {
      setState({
        ...state,
        selectedThumb: null,
        feedback: '',
        submittedFeedback: false,
      })
    }
  }, [pathname])

  return (
    <div className='min-h-screen flex flex-col'>
      <Navbar />
      <main className='py-8 px-4 flex-auto max-w-[630px] mx-auto w-full'>
        <EmailDialog state={state} setState={setState} />
        <ShareDialog state={state} setState={setState} />
        <ErrorDialog state={state} setState={setState} />
        <div className='mb-8'>
          <Breadcrumbs state={state} />
        </div>
        <AnimatePresence mode='wait'>
          <Routes key={location.pathname} location={location}>
            <Route path='/' element={<GenerateStep state={state} setState={setState} />} />
            <Route path='/skills' element={<SkillsStep state={state} setState={setState} />} />
            <Route
              path='/job-post'
              element={
                <FinishStep
                  state={state}
                  setState={setState}
                  onReset={() => setState(INITIAL_STATE)}
                />
              }
            />
          </Routes>
        </AnimatePresence>
      </main>
      <Footer />
    </div>
  )
}

export default App
