import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { ReactComponent as CheckIcon } from '../assets/check-icon.svg'
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'
import copy from 'copy-to-clipboard'
import { AnimatePresence, motion } from 'framer-motion'

const getValue =
  state => `I just used SkillsEngine’s Job Generator to create a ${state.title} posting. Check it out!

${process.env.REACT_APP_PUBLIC_URL}`

function ShareDialog({ state, setState }) {
  const { isShareDialogOpen } = state
  const [copyState, setCopyState] = React.useState('text')
  const handleCopy = () => {
    copy(process.env.REACT_APP_PUBLIC_URL)
    setCopyState('link')
  }

  React.useEffect(() => {
    if (isShareDialogOpen) {
      copy(getValue(state))
    }
  }, [isShareDialogOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClose = () => {
    setState(state => ({
      ...state,
      isShareDialogOpen: false,
    }))
    setCopyState('text')
  }

  return (
    <>
      <Dialog.Root isOpen={state.isShareDialogOpen} onOpenChange={handleOnClose}>
        <AnimatePresence>
          {state.isShareDialogOpen && (
            <Dialog.Portal forceMount>
              <Dialog.Overlay forceMount>
                <motion.div
                  key='overlay'
                  className='fixed inset-0 bg-black bg-opacity-50 z-[2000]'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                />
              </Dialog.Overlay>
              <Dialog.Content>
                <motion.div
                  className='fixed bg-white rounded-lg shadow-2xl p-4 z-[3000] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-lg text-skills-blue-800'
                  style={{ width: 'calc(100% - 16px)' }}
                  key='drop'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Dialog.Title className='text-xl font-bold mb-4'>Spread the word:</Dialog.Title>
                  <div className='block w-full bg-cool-gray-50 text-cool-gray-700 rounded-md px-4 py-3 border border-cool-gray-200 focus:border-amber-400 focus:outline-0 focus:ring-0 transition-colors mb-4'>
                    <p className='mb-4'>
                      I just used SkillsEngine’s Job Generator to create a {state.title} posting.
                      Check it out!
                    </p>
                    <a
                      href={process.env.REACT_APP_PUBLIC_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='underline'
                    >
                      {process.env.REACT_APP_PUBLIC_URL}
                    </a>
                  </div>
                  <div className='flex justify-between'>
                    {copyState === 'text' && (
                      <motion.div
                        key='text'
                        className='bg-[#6EE7B7] text-[#064E3B] border border-[#34D399] flex items-center px-4 rounded-md h-10'
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 0 }}
                        transition={{ duration: 0.5, delay: 2 }}
                      >
                        <div className='w-4 mr-2'>
                          <CheckIcon />
                        </div>
                        Text copied!
                      </motion.div>
                    )}
                    {copyState === 'link' && (
                      <motion.div
                        key='link'
                        className='bg-[#6EE7B7] text-[#064E3B] border border-[#34D399] flex items-center px-4 rounded-md h-10'
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 0 }}
                        transition={{ duration: 0.5, delay: 2 }}
                      >
                        <div className='w-4 mr-2'>
                          <CheckIcon />
                        </div>
                        Link copied!
                      </motion.div>
                    )}
                    <div className='flex'>
                      <button
                        className='bg-cool-gray-50 border border-cool-gray-300 text-cool-gray-900 font-bold flex items-center px-4 rounded-md ml-4 h-10'
                        type='submit'
                        onClick={handleCopy}
                      >
                        Copy link
                      </button>
                      <button
                        className='bg-amber-400 text-cool-gray-900 font-bold flex items-center px-4 rounded-md ml-4 h-10'
                        type='button'
                        onClick={handleOnClose}
                      >
                        Done
                      </button>
                    </div>
                  </div>

                  <button className='p-3 w-12 h-12 absolute top-1 right-1' onClick={handleOnClose}>
                    <CloseIcon />
                  </button>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </>
  )
}

export default ShareDialog
