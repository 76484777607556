import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg'
import { AnimatePresence, motion } from 'framer-motion'

function ErrorDialog({ state, setState }) {
  const handleOnClose = () => {
    setState(state => ({
      ...state,
      isErrorDialogOpen: false,
    }))
  }

  return (
    <>
      <Dialog.Root isOpen={state.isErrorDialogOpen} onOpenChange={handleOnClose}>
        <AnimatePresence>
          {state.isErrorDialogOpen && (
            <Dialog.Portal forceMount>
              <Dialog.Overlay forceMount>
                <motion.div
                  key='overlay'
                  className='fixed inset-0 bg-black bg-opacity-50 z-[2000]'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                />
              </Dialog.Overlay>
              <Dialog.Content>
                <motion.div
                  className='fixed bg-white rounded-lg shadow-2xl p-4 z-[3000] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-lg text-skills-blue-800'
                  style={{ width: 'calc(100% - 16px)' }}
                  key='drop'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Dialog.Title className='text-xl font-bold mb-4'>Error</Dialog.Title>
                  <div className=''>
                    <p className='mb-8'>
                      There was an error processing your query. Please try another query, or try
                      again later.
                    </p>
                  </div>
                  <div className='flex justify-end'>
                    <button
                      className='bg-amber-400 text-cool-gray-900 font-bold flex items-center px-4 rounded-md ml-4 h-10'
                      type='button'
                      onClick={handleOnClose}
                    >
                      Try again
                    </button>
                  </div>
                  <button className='p-3 w-12 h-12 absolute top-1 right-1' onClick={handleOnClose}>
                    <CloseIcon />
                  </button>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </>
  )
}

export default ErrorDialog
