import cc from 'classnames'
import removeEmojis from '../util/removeEmojis'
import React from 'react'

export const inputClasses =
  'block w-full bg-skills-blue-400 placeholder-cool-gray-300 rounded-md px-4 py-3 border border-transparent focus:border-amber-400 focus:outline-0 focus:ring-0 transition-colors truncate'
const Input = ({ inputRef, className, ...props }) => {
  const [value, setValue] = React.useState(props.value)

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = e => {
    const isWhitespace = e.target.value.trim() === ''
    if (isWhitespace) {
      setValue('')
      props.onChange({ target: { value: '' } })
    } else {
      const newValue = removeEmojis(e.target.value)
      setValue(newValue)
      props.onChange({ target: { value: newValue } })
    }
  }

  return (
    <input
      ref={inputRef}
      className={cc(inputClasses, className)}
      type='text'
      {...props}
      onChange={handleChange}
      value={value}
    />
  )
}

export default Input
