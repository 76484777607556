import cc from 'classnames'
import { useLocation, Link } from 'react-router-dom'
import { ChevronRightIcon } from '@chakra-ui/icons'

const Breadcrumbs = ({ state }) => {
  const { pathname } = useLocation()

  const buttonClass =
    'text-cool-gray-400 font-medium uppercase mx-1 hover:bg-white/10 rounded py-2 px-1 transition-colors text-xs sm:text-base'
  return (
    <div className='flex justify-center items-center print:hidden'>
      <Link
        to={state.isLoading ? undefined : '/'}
        className={cc(buttonClass, {
          'text-white': pathname === '/',
          'pointer-events-none': state.isLoading,
        })}
      >
        Generate
      </Link>
      <ChevronRightIcon />
      <Link
        to={state.isLoading ? undefined : '/skills'}
        className={cc(buttonClass, {
          'text-white': pathname === '/skills',
          'pointer-events-none': pathname === '/' || pathname === '/skills' || state.isLoading,
        })}
      >
        Select skills
      </Link>
      <ChevronRightIcon />
      <Link
        to={state.isLoading ? undefined : '/job-post'}
        className={cc(buttonClass, 'pointer-events-none', {
          'text-white': pathname === '/job-post',
        })}
      >
        Finish & Share
      </Link>
    </div>
  )
}

export default Breadcrumbs
